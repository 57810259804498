import { notification } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import api from '../../services/api';
import { DevicesActions, ContractActions } from '../../actions';

export function* addDevices({ data }) {
  try {
    yield Promise.all(
      data.map((device) => api.post('/devices/create-device', device)),
    );

    notification.success({ message: 'Dispositivo adicionado' });
  } catch (error) {
    notification.error({
      message: 'Erro no cadastro do dispositivo',
      description: 'Ocorreu um erro na operação de cadastro do dispositivo.',
    });
  }
}

export function* deleteDevices({ devices }) {
  try {
    yield Promise.all(
      devices.map((dev_eui_id) => api
        .delete(`/devices/${dev_eui_id}`)
        .then(
          notification.success({ message: 'Dispositivo deletado com sucesso!' }),
        )),
    );
  } catch (error) {
    notification.error({
      message: 'Erro no delete!',
      description: 'Ocorreu um erro no processo. Verifique a conexão e tente novamente.',
    });
  }
}

export function* updateDevice({ dev_eui, newData }) {
  try {
    yield api.patch(`/devices/${dev_eui}`, newData);
    notification.success({ message: 'Dispositivo editado' });
  } catch (error) {
    notification.error({
      message: 'Erro na edição do dispositivo',
      description: 'Ocorreu um erro na atualização dos dados. Revise os campos e tente novamente.',
    });
  }
}

export function* getDevicesAndContracts({
  devices_limit,
  devices_offset,
  contracts_limit,
  contracts_offset,
}) {
  try {
    const data = yield Promise.all(
      [api.get('/contracts', { params: { limit: contracts_limit, offset: contracts_offset } }),
        api.get('/devices', { params: { limit: devices_limit, offset: devices_offset } })],
    )
      .then((responses) => {
        const contracts = responses[0].data;
        const devices = responses[1].data;

        return { contracts, devices };
      });

    const { contracts, devices } = data;

    if (contracts.length > 0) { contracts.map((i) => Object.assign(i, { key: `${i.id}` })); }
    if (devices.length > 0) { devices.map((i) => Object.assign(i, { key: `${i.id}` })); }

    const orderDevicesByID = devices.sort(
      (a, b) => {
        if (a.dev_eui > b.dev_eui) return 1;
        if (b.dev_eui > a.dev_eui) return -1;
        return 0;
      },
    );

    // atualiza a lista de dispositivos do devicesReducer
    yield put(DevicesActions.show_devices(orderDevicesByID));
    yield put(ContractActions.update_contracts_list(contracts));
  } catch (error) {
    notification.warning({
      message: 'Sessão expirou!',
      description: 'Faça o login novamente.',
    });
  }
}

export function* get_all_devices({ limit, offset }) {
  try {
    const { data } = yield api.get('/devices', { params: { limit, offset } })
      .then((response) => response);

    if (data.length > 0) { data.map((i) => Object.assign(i, { key: `${i.id}` })); }

    const orderDevicesByID = data.sort(
      (a, b) => {
        if (a.dev_eui > b.dev_eui) return 1;
        if (b.dev_eui > a.dev_eui) return -1;
        return 0;
      },
    );

    yield put(DevicesActions.show_devices(orderDevicesByID));
  } catch (error) {
    notification.warning({
      message: 'Sessão expirou!',
      description: 'Faça o login novamente.',
    });

    localStorage.removeItem('token');
    localStorage.removeItem('user');

    // yield put(AuthActions.login_success());
    yield put(push('/login'));
  }
}

export function* activateDevices({ data, activation }) {
  try {
    yield Promise.all(
      data.listOfDevices.map((dev_eui) => api
        .post(`/devices/${dev_eui}/activation`, { is_active: activation })
        .then((response) => {
          const { block_downlink } = response.data;
          if (!block_downlink) {
            notification.success({ message: `Dispositivo ${dev_eui} foi ativado.` });
          } else {
            notification.success({ message: `Dispositivo ${dev_eui} foi desativado.` });
          }
        })),
    );
  } catch (error) {
    notification.error({ message: 'Erro no processo de ativação!' });
  }
}

// Retorna os dispositivos de apenas um cliente
export function* getClientDevices() {
  try {
    const devices = yield api.get('/devices')
      .then((response) => response.data);

    if (devices.length > 0) {
      devices.map((i) => Object.assign(i, { key: `${i.id}` }));
    }

    const orderDevicesByID = devices.sort(
      (a, b) => {
        if (a.dev_eui > b.dev_eui) return 1;
        if (b.dev_eui > a.dev_eui) return -1;
        return 0;
      },
    );

    // atualiza a lista de dispositivos do devicesReducer
    yield put(DevicesActions.show_devices(orderDevicesByID));
  } catch (warning) {
    notification.warning({ message: 'Sua sessão expirou!' });
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    // yield put(AuthActions.login_success());
    yield put(push('/login'));
  }
}

/*
  MIGRAÇÕES
*/

export function* migrateDevices({ accessKey, devices }) {
  try {
    yield Promise.all(
      devices.map((dev_eui) => api
        .post(`/devices/${dev_eui}/migration`, { token: accessKey })
        .then(() => {
          notification.success({ message: 'Dispositivos migrados' });
        })),
    );
  } catch (error) {
    notification.error({
      message: 'Erro no processo de migração',
      description: 'Ocorreu um erro no processo, verifique a conexão e tente novamente.',
    });
  }
}

export function* getMigrateDevices({ payload }) {
  try {
    const data = yield api.get(`/devices/by_token/${payload}`)
      .then((response) => {
        notification.success({ message: 'Chave válida' });
        return response.data;
      }).catch(() => {
        notification.error({ message: 'erro normal' });
        throw new Error({ message: 'Erro na busca' });
      });

    if (data.length > 0) {
      data.map((i) => Object.assign(i, { key: `${i.dev_eui}` }));
    }

    yield put(DevicesActions.list_migrate_device(data));
  } catch (error) {
    notification.error({
      message: 'Erro na busca',
      description: 'Chave inválida, tente outra chave de acesso',
    });
  }
}
