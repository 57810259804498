/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import AuthReducer from './auth';
import ClientsReducer from './clients';
import UsersReducers from './users';
import SiderReducer from './sider';
import DevicesReducer from './devices';
import ContractsReducer from './contracts';

export default (history) => combineReducers({
  router: connectRouter(history),
  AuthReducer,
  ClientsReducer,
  ContractsReducer,
  DevicesReducer,
  UsersReducers,
  SiderReducer,
});
