import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import difference from 'lodash/difference';
import {
  Transfer,
  Table,
  Tag,
  Typography,
  Divider,
  Button,
  Input,
  Select,
  Row,
  Col,
  Modal,
  Form,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Container } from './styles';
import { DevicesActions } from '../../actions';

const { Option } = Select;
const { Title } = Typography;
const { confirm } = Modal;

const formItemLayout = {
  labelCol: {
    xs: { span: 50 },
    sm: { span: 50 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? 'none' : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const leftTableColumns = [
  {
    dataIndex: 'title',
    title: 'Dev EUI',
  },
  {
    dataIndex: 'tag',
    title: 'Tags',
    render: (tag) => tag.map((item) => <Tag color="green">{item.replace(/["]/g, '')}</Tag>),
  },
  {
    dataIndex: 'description',
    title: 'Informações',
  },
];

const rightTableColumns = [
  {
    dataIndex: 'title',
    title: 'Dev EUI',
  }, {
    dataIndex: 'tag',
    title: 'Tags',
    render: (tag) => tag.map((item) => <Tag color="green">{item.replace(/["]/g, '')}</Tag>),
  },
];

const Migration = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // Variáveis compartilhadas
  const { migrateDevicesList, targetKeys } = useSelector((state) => state.DevicesReducer);
  const { clientsList } = useSelector((state) => state.ClientsReducer);

  // Variáveis de controle
  const mockData = [];

  if (migrateDevicesList) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < migrateDevicesList.length; i++) {
      mockData.push({
        key: i.toString(),
        title: migrateDevicesList[i].dev_eui,
        description: `description of content${i + 1}`,
        tag: migrateDevicesList[i].tags,
        disabled: false,
      });
    }
  }

  const onChange = (nextTargetKeys) => {
    // let count= Object.keys(nextTargetKeys).length;
    // console.log(nextTargetKeys);
    dispatch(DevicesActions.change_target_keys(nextTargetKeys));
  };

  const onFinish = ({ client_id, accessKey }) => {
    confirm({
      title: 'Confirma a Migração do(s) dispositivo(s) ?',
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk() {
        dispatch(DevicesActions.async_migrate_device({
          devices: targetKeys.map((item) => mockData[item].title),
          client_id,
          accessKey,
        }));
        // console.log('Lista de devices',targetKeys.map(item=>mockData[item].title))
      },
      onCancel() {},
    });
  };

  useEffect(() => {
  }, [migrateDevicesList]);

  return (
    <Container>
      <Title>Migração de Dispositivos</Title>

      <Divider />

      <Row>
        <Form
          form={form}
          {...formItemLayout}
          layout="horizontal"
          onFinish={onFinish}
          style={{ textAlign: 'left', padding: 30, width: 1000 }}
        >
          <Col span={8}>
            <Form.Item
              label="Access key"
              name="accessKey"
              rules={[
                {
                  required: true,
                  message: 'Adicione a chave de acesso!',
                },
              ]}
            >
              <Input.Search
                style={{ marginBottom: 8, width: 350 }}
                placeholder="Digite a Chave"
                onSearch={(value) => dispatch(DevicesActions.async_list_migrate_device(value))}
                enterButton
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Cliente"
              name="client_id"
              rules={[
                {
                  required: true,
                  message: 'Selecione o cliente!',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Selecione o Cliente"
                optionFilterProp="children"
                filterOption={
                  (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
              >
                {clientsList.map(
                  (client) => (
                    <Option key={client.id} value={client.id}>
                      {client.company_name}
                    </Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>

          <div>
            <TableTransfer
              dataSource={mockData}
              targetKeys={targetKeys}
              showSearch
              onChange={onChange}
              filterOption={
                (inputValue, item) => item
                  .title.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                || item
                  .tag.map(
                    (subItem) => subItem.toLowerCase(),
                  ).indexOf(inputValue.toLowerCase()) !== -1
}
              leftColumns={leftTableColumns}
              rightColumns={rightTableColumns}
            />
          </div>

          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ marginTop: 8 }}>
                Migrar dispositivos
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
    </Container>
  );
};

export default Migration;
