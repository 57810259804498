import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaskedInput from 'antd-mask-input';
import {
  Form,
  Input,
  Button,
  Modal,
  Typography,
  Select,
} from 'antd';
import {
  ShoppingOutlined,
  UserOutlined,
  MailOutlined,
  ExclamationCircleOutlined,
  PhoneOutlined,
} from '@ant-design/icons';

import { Container } from './styles';
import { ClientsActions } from '../../../actions';

function ClientsRegister() {
  // Compornentes do antd
  const { confirm } = Modal;
  const { Title } = Typography;
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [options, setOption] = useState('cnpj');
  const [selectProvider, setSelectProvider] = useState(false);
  const { clientsList } = useSelector((state) => state.ClientsReducer);

  // Disparo da açaão para adicioanr um cliente
  async function onFinish({
    cpf_cnpj, company_name, email, phone, provider_id, c_type,
  }) {
    // const parsedCpfCnpj = cpf_cnpj.replace(/\D/g, '');
    const parsedPhone = parseInt(phone, 10);

    confirm({
      title: 'Confirma o cadastro do cliente ?',
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk() {
        dispatch(ClientsActions.async_add_client({
          company_name,
          c_type,
          is_active: true,
          email,
          phone: parsedPhone,
          cpf_cnpj,
          provider_id,
        }));
      },
    });
  }

  function handleChangeClientType(values) {
    if (values === 'provider') {
      setSelectProvider(true);
    } else if (values === 'client') {
      setSelectProvider(false);
    }
  }

  const handleChangeOption = (value) => {
    setOption(value);
  };

  const selectBefore = (
    <Select defaultValue="cnpj" onChange={handleChangeOption}>
      <Option value="cnpj">CNPJ</Option>
      <Option value="cpf">CPF</Option>
    </Select>
  );

  return (
    <Container>
      <Title>Cadastrar Clientes</Title>

      <Form
        form={form}
        labelCol={{ span: 20 }}
        wrapperCol={{ span: 14 }}
        layout="vertical"
        onFinish={onFinish}
        style={{
          textAlign: 'left',
          padding: 30,
          width: 1000,
          margin: '20px 25%',
        }}
      >
        <Form.Item
          label="Nome do cliente"
          name="company_name"
          rules={[
            {
              required: true,
              message: 'Por favor adicione o nome do cliente!',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Nome do cliente"
            maxlength="50"
          />
        </Form.Item>

        <Form.Item
          label="Telefone"
          name="phone"
          rules={[
            {
              required: true,
              message: 'Por favor adicione um número para telefone!',
            },
          ]}
        >
          <Input
            size="large"
            maxlength="11"
            prefix={<PhoneOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item
          label="CNPJ ou CPF"
          name="cpf_cnpj"
          rules={[
            {
              required: true,
              message: 'Por favor adicione o CNPJ!',
            },
            {
              pattern: /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
              whitespace: true,
              message: `${options} inválido`,
            },
          ]}
        >
          <MaskedInput
            addonBefore={selectBefore}
            style={{ width: '100%' }}
            size="large"
            mask={options === 'cnpj' ? '11.111.111/1111-11' : '111.111.111-11'}
            prefix={<ShoppingOutlined className="site-form-item-icon" />}
            placeholder={`Digite o seu ${options.toUpperCase()}`}
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'O Email digitado é invalido!',
            },
            {
              required: true,
              message: 'Por favor digite o Email!',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Digite o seu Email"
          />
        </Form.Item>

        <Form.Item
          label="Categoria"
          name="c_type"
          rules={[{
            required: true,
            message: 'Selecione a categoria do cliente',
          }]}
        >
          <Select onChange={handleChangeClientType}>
            <Option value="client">Cliente</Option>
            <Option value="provider">Fornecedor</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Fornecedor"
          name="provider_id"
          rules={[{
            required: !selectProvider,
            message: 'Selecione o fornecedor',
          }]}
        >
          <Select
            showSearch
            disabled={selectProvider}
            placeholder="Selecione o Cliente"
            optionFilterProp="children"
            filterOption={
              (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          >
            {clientsList.filter((client) => client.c_type === 'provider')
              .map((obj) => (
                <Option key={obj.id} value={obj.id}>
                  {obj.company_name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              // disabled={
              //   !form.isFieldsTouched(true)
              //   || form.getFieldsError().filter(({ errors }) => errors.length)
              //     .length
              // }
            >
              Cadastrar
            </Button>
          )}
        </Form.Item>
      </Form>
    </Container>
  );
}

export default ClientsRegister;
