import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tag } from 'antd';

const ExpendableRow = ({ record_id, users }) => {
  const expendableData = users.filter((user) => user.client_id === record_id);

  const columns = [
    {
      title: 'Usuário',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'user_email',
    },

    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'user_is_active',
      render: (is_active) => (
        <>
          {is_active ? (
            <Tag color="green">Ativado</Tag>
          ) : (
            <Tag color="red">Desativado</Tag>
          )}
        </>
      ),
    },
  ];

  return (
    <Table columns={columns} dataSource={expendableData} pagination={false} />
  );
};

ExpendableRow.propTypes = {
  record_id: PropTypes.func.isRequired,
  users: PropTypes.func.isRequired,
};

export default ExpendableRow;
