import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Col, Row, Button, Select,
} from 'antd';
import MaskedInput from 'antd-mask-input';

const UpdateForm = ({ values, providers, onFinish }) => {
  const { Option } = Select;
  const [form] = Form.useForm();

  const {
    company_name,
    email,
    cpf_cnpj,
    phone,
    c_type,
    is_active,
    key,
    provider_id,
  } = values;

  const parsedCpfCnpj = cpf_cnpj.replace(/\D/g, '');
  const [options, setOption] = useState('cpf');

  const handleChangeOption = (value) => {
    setOption(value);
  };

  function identifyCPFOrCNPJ() {
    if (parsedCpfCnpj.length > 11) {
      setOption('cnpj');
      return 'cnpj';
    }
    setOption('cpf');
    return 'cpf';
  }

  const selectBefore = (
    <Select defaultValue={identifyCPFOrCNPJ} onChange={handleChangeOption}>
      <Option value="cnpj">CNPJ</Option>
      <Option value="cpf">CPF</Option>
    </Select>
  );

  return (
    <>
      <Form
        form={form}
        key={`${key}form`}
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          company_name,
          email,
          cpf_cnpj: parsedCpfCnpj,
          phone,
          c_type,
          is_active,
          provider_id,
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Nome"
              name="company_name"
              rules={[{
                required: true,
                message: 'Por favor, insira o nome！',
              }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{
                required: true,
                message: 'Por favor, insira o email',
              }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Telefone"
              name="phone"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Status do cliente"
              name="is_active"
            >
              <Select>
                <Option value>Ativado</Option>
                <Option value={false}>Desativado</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              label="CPF/CNPJ"
              name="cpf_cnpj"
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira o cpf ou CNPJ！',
                },
                {
                  pattern: /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
                  whitespace: true,
                  message: 'CPF ou CNPJ é inválido',
                },
              ]}
            >
              <MaskedInput
                addonBefore={selectBefore}
                style={{ width: '100%' }}
                size="large"
                mask={options === 'cnpj' ? '11.111.111/1111-11' : '111.111.111-11'}
                placeholder={`Digite o seu ${options.toUpperCase()}`}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Item
              label="Fornecedor"
              name="provider_id"
              // rules={[{
              //   required: true,
              //   message: 'Por favor, selecione o fornecedor！',
              // }]}
            >
              <Select
                showSearch
                placeholder="Selecione o Cliente"
                optionFilterProp="children"
                filterOption={
                  (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
              >
                {providers.filter((provider) => provider.c_type === 'provider')
                  .map((provider) => (
                    <Option key={provider.key} value={provider.id}>
                      {provider.company_name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              Salvar
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

UpdateForm.propTypes = {
  values: PropTypes.func.isRequired,
  providers: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default UpdateForm;
