import React from 'react';

import ClientsRegister from '../Clients/ClientsRegister';
import ClientsManagement from '../Clients/ClientsManagement/clientsManagement';
import UserRegister from '../Clients/UserRegister';
import DeviceRegister from '../Devices/register';
import DevicesManagement from '../Devices/management';
// import DeviceSchedule from '../components/Devices/DeviceSchedule/deviceSchedule';
import Migration from '../Migrations';
// import Billing from '../components/Billing';
import Users from '../Users';
import Contracts from '../Contracts';
import AdmiUserSettings from '../Admin';

const SubRoutes = [
  {
    path: '/',
    exact: true,
    component: () => <h1>Bem vindo</h1>,
    key: 'home-view',
  },
  {
    path: '/user-settings',
    component: () => <AdmiUserSettings />,
    key: 'user-settings',
  },
  {
    path: '/users',
    component: () => <Users />,
    key: 'users',
  },
  {
    path: '/client-register',
    component: () => <ClientsRegister />,
    key: 'client-register',
  },
  {
    path: '/clients-management',
    component: () => <ClientsManagement />,
    key: 'clients-management',
  },
  {
    path: '/user-register',
    component: () => <UserRegister />,
    key: 'user-register',
  },
  {
    path: '/device-register',
    component: () => <DeviceRegister />,
    key: '/device-register',
  },
  {
    path: '/device-management',
    component: () => <DevicesManagement />,
    key: 'devices-management',
  },
  {
    path: '/migration',
    component: () => <Migration />,
    key: 'devices-migration',
  },
  {
    path: '/contracts',
    component: () => <Contracts />,
    key: 'contracts',
  },
  // {
  //   path: '/billing',
  //   component: () => <Billing />,
  // },
  // {
  //   path: '/scheduling',
  //   component: () => <DeviceSchedule />,
  // },
];

export default SubRoutes;
