import { put } from 'redux-saga/effects';
import { notification } from 'antd';

// import { push } from 'connected-react-router';
import api from '../../services/api';
import { ClientsActions, UsersActions } from '../../actions';

export function* clientsActivation({ activation, data }) {
  try {
    yield Promise.all(
      data.listOfClients.map((id) => api
        .post(`/clients/${id}/activation`, { is_active: activation })
        .then((response) => {
          const { is_active } = response.data;
          if (is_active) {
            notification.success({ message: 'Cliente foi ativado.' });
          } else {
            notification.success({ message: 'Cliente foi desativado.' });
          }
        })
        .catch(() => notification.error({ message: 'Ocorreu algum erro durante a ativação do cliente.' }))),
    );
  } catch (error) {
    notification.error({ message: 'Erro no processo de ativação!' });
  }
}

export function* getClientsAndUsers({
  clients_limit = 100,
  clients_offset = 0,
  users_limit = 100,
  users_offset = 0,
}) {
  try {
    const data = yield Promise.all(
      [api.get('/clients', { params: { limit: clients_limit, offset: clients_offset } }),
        api.get('/users', { params: { limit: users_limit, offset: users_offset } })],
    )
      .then((responses) => {
        const clients = responses[0].data;
        const users = responses[1].data;
        return { clients, users };
      })
      .catch(() => {});

    const { clients, users } = data;

    // adiciona uma key para cara item
    if (clients.length > 0) {
      clients.map((i) => Object.assign(i, { key: `${i.id}` }));
    }
    // ordena a lista
    const sortedClients = clients.sort(
      (a, b) => {
        if (a.company_name.toLowerCase() > b.company_name.toLowerCase()) return 1;
        if (b.company_name.toLowerCase() > a.company_name.toLowerCase()) return -1;
        return 0;
      },
    );

    if (users.length > 0) { users.map((i) => Object.assign(i, { key: `${i.id}` })); }

    yield put(UsersActions.show_users(users)); // atualiza a lista de usuários
    yield put(ClientsActions.show_clients(sortedClients)); // atualiza a lista de clientes
  } catch (warning) {
    notification.warning({
      message: 'Sessão expirou!',
      description: 'Faça o login novamente.',
    });
  }
}

export function* get_all_clients({ limit, offset }) {
  try {
    const { data } = yield api.get('/clients', { params: { limit, offset } })
      .then((response) => response);

    if (data.length > 0) {
      data.map((i) => Object.assign(i, { key: `${i.id}` }));
    }

    const sortedClients = data.sort(
      (a, b) => {
        if (a.company_name.toLowerCase() > b.company_name.toLowerCase()) return 1;
        if (b.company_name.toLowerCase() > a.company_name.toLowerCase()) return -1;
        return 0;
      },
    );

    yield put(ClientsActions.show_clients(sortedClients));
  } catch (error) {
    notification.error({
      message: 'Sessão expirou!',
      description: 'Faça o login novamente.',
    });
  }
}

export function* removeClient({ data }) {
  try {
    yield Promise.all(
      data.map((client_id) => api.delete(`/clients/${client_id}`, { timeout: 10000 })
        .then(() => {
          notification.success({ message: 'Cliente deletado com sucesso.' });
        })
        .catch(() => {
          notification.error({ message: 'Ocorreu algum erro. Revise sua conexão!' });
        })),
    );
  } catch (error) {
    notification.error({
      message: 'Erro no delete',
      description: 'Ocorreu um erro no processo. Verifique a conexão e tente novamente.',
    });
  }
}

export function* updateClient({ id, newData }) {
  try {
    yield api.patch(`/clients/${id}`, newData, { timeout: 10000 })
      .then(
        notification.success({ message: 'Cliente editado com sucesso!' }),
      );
  } catch (error) {
    notification.error({
      message: 'Erro na edição',
      description: 'Ocorreu um erro na atualização dos dados. Revise os campos e tente novamente',
    });
  }
}

export function* addClient({ data }) {
  try {
    yield api.post('/clients', data);

    notification.success({
      message: 'Cadastro realizado!',
      description: 'Acesse o meno para gerenciar todos os Clientes cadastrados.',
    });
  } catch (error) {
    notification.error({
      message: 'Erro no cadastro do cliente!',
      description: 'Revise as informações, ou verifique a conexão e tente novamente.',
    });
  }
}

// Gets the client/provider info & his clients list
export function* getClientInfo() {
  try {
    const clientInfo = yield api.get('/clients', { params: { limit: 1, offset: 0 } })
      .then((response) => response.data);

    if (clientInfo.length > 0) {
      clientInfo.map((i) => Object.assign(i, { key: `${i.id}` }));
    }

    if (localStorage.getItem('user') === '3') {
      yield put(ClientsActions.show_one_client(clientInfo[0]));
    } else if (localStorage.getItem('user') === '2') {
      yield put(ClientsActions.show_one_client(clientInfo[0]));
    }
  } catch (warning) {
    notification.warning({
      message: 'Sua sessão expirou!',
      description: 'Faça o login novamente.',
    });
  }
}

export function* get_providers_client({ limit, offset }) {
  try {
    const clientInfo = yield api.get('/clients', { params: { limit, offset } })
      .then((response) => response.data);

    if (clientInfo.length > 0) {
      clientInfo.map((i) => Object.assign(i, { key: `${i.id}` }));
    }

    if (!offset) {
      clientInfo.splice(0, 1);
    }

    const sortedClients = clientInfo.sort(
      (a, b) => {
        if (a.company_name.toLowerCase() > b.company_name.toLowerCase()) return 1;
        if (b.company_name.toLowerCase() > a.company_name.toLowerCase()) return -1;
        return 0;
      },
    );

    yield put(ClientsActions.show_clients(sortedClients));
  } catch (warning) {
    notification.warning({
      message: 'Sua sessão expirou!',
      description: 'Faça o login novamente.',
    });
  }
}
