import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input,
  Form,
  Button,
  Select,
  Modal,
} from 'antd';
import {
  CloseCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { UsersActions } from '../../../../actions';

function UpdateUserModal({
  visible, onCancel, user_info,
}) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const { clientsList } = useSelector((state) => state.ClientsReducer);

  const {
    id,
    email,
    is_active,
    name,
    client_id,
    user_type_id,
  } = user_info;

  // console.log(user_info);

  const [userType, setUserType] = useState(user_type_id === 1);

  async function handleUpdateUser(values) {
    setConfirmLoading(true);

    dispatch(UsersActions.async_update_user(id, {
      name: values.name,
      email: values.email,
      password: values.password,
      client_id: values.client_id,
      user_type_id: values.user_type_id,
      is_active: values.is_active,
    }));

    setConfirmLoading(false);
  }

  function handleChangeUserType(value) {
    if (value === 1) {
      setUserType(true);
    } else {
      setUserType(false);
    }
  }

  return (
    <Modal
      title={`Edição do usuário ${id}`}
      key={id}
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      footer={null}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      keyboard
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleUpdateUser}
        style={{ textAlign: 'left', padding: 30, width: '100%' }}
        initialValues={{
          email,
          is_active,
          name,
          client_id,
          user_type_id,
        }}
      >

        <Form.Item
          label="Tipo do usuário"
          name="user_type_id"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o cliente!',
            },
          ]}
        >
          <Select size="large" onChange={handleChangeUserType}>
            <Option value={1}>Adminstrador</Option>
            <Option value={2}>Cliente</Option>
            <Option value={3}>Fornecedor</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Nome do usuário"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor adicione o username',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<UserOutlined />}
          />
        </Form.Item>

        <Form.Item
          label="Cliente"
          name="client_id"
          rules={[
            {
              required: !userType,
              message: 'Por favor selecione o cliente!',
            },
          ]}
        >
          <Select
            showSearch
            disabled={userType}
            size="large"
            placeholder="Selecione o Cliente"
            optionFilterProp="children"
            filterOption={
             (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
          >
            {clientsList.map((client) => (
              <Option key={client.key} value={client.id}>
                {client.company_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Email inválido!',
            }, {
              required: true,
              message: 'Por favor digite seu email!',
            }]}
        >
          <Input
            size="large"
            prefix={<UserOutlined />}
            placeholder="exemplo@email.com"
          />
        </Form.Item>

        <Form.Item
          label="Status"
          name="is_active"
        >
          <Select>
            <Option value>Ativado</Option>
            <Option value={false}>Desativado</Option>
          </Select>
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              Salvar
            </Button>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

UpdateUserModal.propTypes = {
  user_info: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UpdateUserModal;
