import { ACTION_TYPES } from '../../actions/devicesActions';

const INITIAL_STATE = {
  devicesList: [],
  activeAbp: 1,
  encryption: 1,

  device: {
    dev_eui_id: String, // "cb105012c807695l" input normal(hexa)
    tags: ['Default tag'],
    activation: 'ABP', // ABP OU OTAA
    adr: {
      tx_power: null, // null
      datarate: null, // null
      mode: 'on', // ON / OFF / STATIC
    },
    contract_id: Number,
    app_eui: '494b284ab1eca29b', // input normal(hexa)
    app_key: null, // "ok1n31203123981jknldmd", // input normal(hexa)
    appskey: null, // "1c8a76070844d1d7a5e5e29f97f28686",//SE NS = TRUE
    band: 'LA915-928A', // "LA915-928A" padrão latina americana (915-928mhz)
    block_downlink: 'True', // padrao "true"
    block_uplink: 'True', // padrao "true"
    counter_down: Number, // estado inicial 0
    counter_up: Number, // estado inicial 0
    counters_size: Number, // 4 ou 2
    dev_addr: '5a27f147', // SE ABP = TRUE // input normal(hexa)
    dev_class: 'A', // 'A' ou 'C'
    encryption: 'APP', // APP OU NS
    // geolocation: null, // NÃO OBRIGATORIO
    // last_activity: null, // padrão "null"
    // last_join: null, // padrão "null" //fora da interface
    nwkskey: '95196a50c94397b8b67c2581b6671d04', // (NETWORK S KEY) SE ABP = TRUE //input normal(hexa)
    device_type: 'test',
    rx1: { // NÃO OBRIGATORIO
      // "rxteste": "adasds",
      // "rarar": "asd13"
      delay: 1,
    },
    strict_counter: false,
  },

  loadMoreDevices: true,
  // itens de migração
  migrateDevicesList: [],
  targetKeys: [],
};

const DevicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SHOW_DEVICES:
      return { ...state, devicesList: [...state.devicesList, ...action.payload] };

    case ACTION_TYPES.CHANGE_TARGET_KEYS:
      return { ...state, targetKeys: action.payload };

    case ACTION_TYPES.LIST_MIGRATE_DEVICE:
      return { ...state, migrateDevicesList: action.payload };

    case ACTION_TYPES.LOAD_MORE_DEVICES:
      return { ...state, loadMoreDevices: action.value };

    case ACTION_TYPES.CLEAR_DEVICES_LIST:
      return { ...state, devicesList: [] };

    default:
      return state;
  }
};

export default DevicesReducer;
