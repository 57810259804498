import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Select,
  Modal,
  Row,
  Col,
  Typography,
  Divider,
} from 'antd';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { DevicesActions } from '../../../actions';
import EditableTagGroup from './components/tags';
import { Container } from './styles';

const DeviceRegister = () => {
  const { Option } = Select;
  const { confirm } = Modal;
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [listOfTags, setListOfTags] = useState([]);
  const [activeABP, setAcitveABP] = useState(true);
  const [encryptNS, setEncrypNS] = useState(true);
  const [clientContracts, setClientContacts] = useState([]);

  const { device } = useSelector((state) => state.DevicesReducer);
  const { clientsList } = useSelector((state) => state.ClientsReducer);
  const { contractsList } = useSelector((state) => state.ContractsReducer);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  // Função para modificação no estado de Ativação do dispositivo
  const activationChange = (values) => {
    if (values === 'ABP') {
      setAcitveABP(false);
    } else {
      setAcitveABP(true);
    }
  };

  // Função para setar a criptografia do dispositivo
  const encryptionChange = (values) => {
    if (values === 'NS') {
      setEncrypNS(false);
    } else {
      setEncrypNS(true);
    }
  };

  function handleUpdateTags(tags) {
    setListOfTags(tags);
  }

  function findClientContractById(client_id) {
    const clientContract = contractsList.filter(
      (contract) => client_id === contract.client_id,
    );

    setClientContacts(clientContract);
  }

  // Cadastro de dispositivos
  async function onFinish(values) {
    device.dev_eui = values.dev_eui;
    device.contract_id = values.contract_id;
    device.tags = listOfTags;
    device.activation = values.activation;
    device.nwkskey = values.nwkskey || null;
    device.dev_addr = values.dev_addr;
    device.app_eui = values.app_eui;
    device.app_key = values.app_key || null;
    device.counters_size = values.counters_size;
    device.dev_class = values.dev_class;
    device.encryption = values.encryption;
    device.appskey = values.appskey || null;

    // console.log(`VEM DAQUI: ${listOfTags}`);

    confirm({
      title: 'Confirma o cadastro do dispositivo ?',
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk() {
        dispatch(DevicesActions.async_add_device([device]));
      },
    });
  }

  useEffect(() => {}, [listOfTags]);

  return (
    <div id="device-registry">
      <Container>
        <Title>Cadastro de Dispositivos</Title>

        <Divider />

        <Form
          form={form}
          {...formItemLayout}
          layout="vertical"
          onFinish={onFinish}
          size="large"
          style={{ textAlign: 'left', padding: 30, width: '100%' }}
        >
          <Form.Item
            label="Cliente"
            name="client_id"
            tooltip={{
              title: 'Selecione o cliente que o dispositivo estará associado',
              icon: <InfoCircleOutlined />,
            }}
            rule={[
              {
                required: false,
                message: 'Selecione um cliente',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Selecione o cliente"
              optionFilterProp="children"
              onChange={(value) => findClientContractById(value)}
              filterOption={
                  (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
            >
              {clientsList.map(
                (client) => (
                  <Option key={client.id} value={client.id}>
                    {client.company_name}
                  </Option>
                ),
              )}
            </Select>

          </Form.Item>

          <Form.Item
            label="Contrato"
            name="contract_id"
            tooltip={{
              title: 'Caso não seja listado nenhum contrato, crie um posteriormente e atualize as informações do dispositivo.',
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: false,
                message: 'Por favor selecione um contrato!',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Selecione o Contrato"
              optionFilterProp="children"
            >
              {clientContracts.map((contract) => (
                <Option key={contract.key} value={contract.id}>
                  {contract.id}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col>
              <Form.Item
                label="Device EUI"
                name="dev_eui"
                rules={[
                  {
                    required: true,
                    message: 'Por favor adicione o Device EUI!',
                  },
                ]}
              >
                <Input
                  style={{ width: 450 }}
                  placeholder="Device EUI"
                  maxLength="16"
                />
              </Form.Item>
            </Col>

            <Col>
              <h3>Tags</h3>
              <Form.Item
                name="tags"
                rules={[
                  {
                    required: false,
                    message: 'Por favor adicione ao menos uma tag!',
                    type: 'array',
                  },
                ]}
              >
                <EditableTagGroup
                  newtags={handleUpdateTags}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} justify="space-between">
            <Col>
              <Form.Item
                label="Modo de ativação"
                name="activation"
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecione a Ativação!',
                  },
                ]}
              >
                <Select
                  style={{ width: 450 }}
                  placeholder="Ativação"
                  onChange={activationChange}
                >
                  <Option key="ABP" value="ABP">ABP</Option>
                  <Option key="OTAA" value="OTAA">OTAA</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                label="NwsKey"
                name="nwkskey"
                tooltip={{
                  title: 'Networks Key.',
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: false,
                    message: 'Por favor adicione o NwsKey!',
                  },
                ]}
              >
                <Input
                  style={{ width: 450 }}
                  placeholder="Nwskey"
                  disabled={activeABP}
                  maxLength="32"
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                label="Dev Address"
                name="dev_addr"
                rules={[
                  {
                    required: false,
                    message: 'Por favor adicione o Device Address!',
                  },
                ]}
              >
                <Input
                  style={{ width: 450 }}
                  placeholder="Dev Address"
                  disabled={activeABP}
                  maxLength="8"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} justify="space-between">
            <Col>
              <Form.Item
                label="App EUI"
                name="app_eui"
                rules={[
                  {
                    required: true,
                    message: 'Por favor adicione o App EUI!',
                  },
                ]}
              >
                <Input
                  style={{ width: 450 }}
                  placeholder="App EUI"
                  maxLength="16"
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                label="Counter Size"
                name="counters_size"
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecione o Counter Size!',
                  },
                ]}
              >
                <Select style={{ width: 450 }} placeholder="Counter">
                  <Option key="counter_size_4" value={4}>4</Option>
                  <Option key="counter_size_2" value={2}>2</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                label="Dev Class"
                name="dev_class"
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecione o Dev Class!',
                  },
                ]}
              >
                <Select style={{ width: 450 }} placeholder="Dev">
                  <Option key="A" value="A">A</Option>
                  <Option key="C" value="C">C</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between">
            <Col>
              <Form.Item
                label="Encriptação"
                name="encryption"
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecione o Encryption!',
                  },
                ]}
              >
                <Select
                  style={{ width: 450 }}
                  placeholder="Encryption"
                  onChange={encryptionChange}
                >
                  <Option key="APP" value="APP">APP</Option>
                  <Option key="NS" value="NS">NS</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                label="Apps KEY"
                name="appskey"
                rules={[
                  {
                    required: false,
                    message: 'Por favor adicione o Apps Key!',
                  },
                ]}
              >
                <Input
                  style={{ width: 450 }}
                  placeholder="Apps KEY"
                  maxLength="32"
                  disabled={encryptNS}
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                label="App Key"
                name="app_key"
                rules={[
                  {
                    required: false,
                    message: 'Por favor adicione o App Key!',
                  },
                ]}
              >
                <Input
                  style={{ width: 450 }}
                  placeholder="App KEY"
                  maxLength="32"
                  disabled={encryptNS}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Cadastrar
            </Button>
          </Form.Item>
        </Form>

      </Container>
    </div>
  );
};

export default DeviceRegister;
