import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

// import { LoginForm } from './styles';
import { AuthActions } from '../../actions';
import './test.css';

function Login() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { token, signInLoading } = useSelector((state) => state.AuthReducer);

  async function signIn({ email, password }) {
    setLoading(true);
    dispatch(AuthActions.sign_in_request({
      email,
      password,
    }));
    // setLoading(false);
  }

  useEffect(() => {
    if (!token) { setLoading(false); }
  }, [loading, signInLoading]);

  return (
    <div className="login-container">

      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        onFinish={signIn}
        size="large"
      >
        <Form.Item
          name="email"
          rules={[
            // {
            //   type: 'email',
            //   message: 'Email inválido!',
            // },
            {
              required: true,
              message: 'Por favor digite seu email!',
            }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="email@exemplo.com"
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Por favor insira a senha!' }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Senha"
            allowClear
          />
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
              loading={loading || signInLoading}
              disabled={
                  !form.isFieldsTouched(true)
                  || form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
            >
              Entrar
            </Button>
          )}
        </Form.Item>
      </Form>

      {/* </LoginForm> */}
    </div>
  );
}

export default Login;
