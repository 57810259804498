import { ACTION_TYPES } from '../../actions/usersActions';

const INITIAL_STATE = {
  usersList: [],
  loading: false,
  user: {
    email: String,
    is_active: Boolean,
    name: String,
    client_id: Number,
    id: Number,
    user_type_id: Number,
    password: String,
    // created_at: String, // formato: "2021-02-11T13:27:17.312Z",
    // updated_at: String, // formato: "2021-02-11T13:27:17.312Z"
  },

  modal_visibility: false,
  loadMoreUsers: true,
  updatePasswordModal: false,

  update_modal_visibility: false,
  update_user: {
    id: Number,
    email: String,
    is_active: Boolean,
    name: String,
    client_id: Number,
    user_type_id: Number,
  },

  user_id: -1,
};

const UsersReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SHOW_USERS:
      return { ...state, usersList: [...state.usersList, ...action.users_data] };

    case ACTION_TYPES.ENABLE_CREATE_USER_MODAL:
      return { ...state, modal_visibility: action.visibility };

    case ACTION_TYPES.ENABLE_UPDATE_USER_MODAL:
      return {
        ...state,
        update_modal_visibility: action.visibility,
        user_id: action.user_id,
        update_user: {
          id: action.user_id,
          email: action.user_info.email,
          is_active: action.user_info.is_active,
          name: action.user_info.name,
          client_id: action.user_info.client_id,
          user_type_id: action.user_info.user_type_id,
        },
      };

    case ACTION_TYPES.DISABLE_UPDATE_USER_MODAL:
      return { ...state, update_modal_visibility: action.visibility };

    case ACTION_TYPES.CLEAR_USERS_LIST:
      return { ...state, usersList: [] };

    case ACTION_TYPES.LOAD_MORE_USERS:
      return { ...state, loadMoreUsers: action.value };

    case ACTION_TYPES.ENABLE_UPDATE_PASSWORD_USER_MODAL:
      return {
        ...state,
        updatePasswordModal: action.visibility,
        user_id: action.user_id,
        update_user: {
          id: action.user_id,
          email: action.user_info.email,
          is_active: action.user_info.is_active,
          name: action.user_info.name,
          client_id: action.user_info.client_id,
          user_type_id: action.user_info.user_type_id,
        },
      };

    case ACTION_TYPES.DISABLE_UPDATE_PASSWORD_MODAL:
      return { ...state, updatePasswordModal: action.visibility };

    default:
      return state;
  }
};

export default UsersReducers;
