import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  // Button,
  Layout,
  Card,
  Typography,
} from 'antd';

import { UsersActions } from '../../actions';
import UsersTable from './subComponents/UsersTable/userTable';
import CreateUserModal from './subComponents/CreateUserModal/createUserModal';
import UpdateUserModal from './subComponents/UpdateUserModal/updateUserModal';
import './styles.css';

const UsersManagement = () => {
  const dispatch = useDispatch();
  const { Title } = Typography;
  const {
    modal_visibility,
    update_user,
    update_modal_visibility,
    user_id,
  } = useSelector((state) => state.UsersReducers);

  const { id } = update_user;
  // console.log(user_id);

  useEffect(() => { }, [modal_visibility, update_user, user_id]);

  return (
    <div className="users-container">

      <Title>Gerenciamento de usuários</Title>

      <Layout style={{ minWidth: '90vw' }}>
        <Card style={{ margin: 'auto', width: '100%', minHeight: '100vh' }}>
          <UsersTable />
        </Card>

      </Layout>

      <CreateUserModal
        visible={modal_visibility}
        onCancel={() => dispatch(UsersActions.enable_create_user_modal(false))}
      />

      <UpdateUserModal
        key={id}
        id={id}
        user_info={update_user}
        visible={update_modal_visibility}
        onCancel={() => dispatch(UsersActions.disable_update_user_modal(false))}
      />

    </div>
  );
};

export default UsersManagement;
