import { notification } from 'antd';
import { put } from 'redux-saga/effects';

import api from '../../services/api';
import { ContractActions } from '../../actions';

export function* createContracts({ contract_data }) {
  try {
    yield api.post('/contracts/create-contract', contract_data);
    notification.success({ message: 'Contrado registrado.' });
  } catch (error) {
    notification.error({
      message: 'Erro no registro!',
      description: 'Ocorreu um erro ao registrar o contrato. Revise as informações e tente novamente.',
    });
  }
}

export function* deleteContract({ listOfContractsID }) {
  try {
    yield Promise.all(
      listOfContractsID.map((contract) => api.delete(`/contracts/${contract}`)),
    );

    if (listOfContractsID.length >= 1) {
      notification.success({ message: 'Contrato deletado.' });
    } else notification.success({ message: 'Contratos deletados.' });
  } catch (error) {
    notification.error({
      message: 'Erro na remoção do contrato!',
      description: 'Ocorreu um erro no processo, verifique sua conexão.',
    });
  }
}

export function* updateContract({ contract_id, contract_data_updated }) {
  try {
    yield api.patch(`/contracts/${contract_id}`, contract_data_updated);
    notification.success({ message: 'Contrato editado.' });
  } catch (error) {
    notification.error({
      message: 'Erro na edição do contrato!',
      description: 'Verifique os dados preenchidos ou sua conexão',
    });
  }
}

export function* getOneContract({ contract_id }) {
  try {
    yield api.get(`/contracts/${contract_id}`).then(
      notification.success({ message: 'Contrato encontrado.' }),
    );
  } catch (error) {
    notification.error({
      message: 'Falha na pesquisa do contrato!',
      description: 'Contrato inexistente ou falha na conexão.',
    });
  }
}

export function* getAllContracts({ limit, offset }) {
  try {
    const { data } = yield api.get('/contracts', { params: { limit, offset } })
      .then((response) => response);

    if (data.length > 0) {
      data.map((i) => Object.assign(i, { key: `${i.id}` }));
    }

    yield put(ContractActions.update_contracts_list(data));
  } catch (error) {
    notification.error({
      message: 'Sua sessão expirou',
      description: 'Faça o login novamente',
    });
  }
}
