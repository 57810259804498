import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Select,
  Modal,
} from 'antd';
import {
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { DevicesActions } from '../../actions';
// import EditableTagGroup from '../../';
import EditableTagGroup from '../Devices/register/components/tags';

const DeviceRegister = ({ visible, onCancel }) => {
  const { Option } = Select;
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // const [newTag, setNewTag] = useState('');
  const [listOfTags, setListOfTags] = useState([]);
  const [activeABP, setAcitveABP] = useState(true);
  const [encryptNS, setEncrypNS] = useState(true);

  const { device } = useSelector((state) => state.DevicesReducer);
  const { contractsList } = useSelector((state) => state.ContractsReducer);
  /*
    As tags por enquanto estão sendo relacionadas ao plano do cliente.
    Então é necessário discutir isso
    Já as outras variáveis são características padrão do dispositivo
  */
  // const tagsOptions = ['Novo device'];

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  // ---------------------------------
  const activationChange = (values) => {
    if (values === 'ABP') {
      setAcitveABP(false);
    } else {
      setAcitveABP(true);
    }
  };

  const encryptionChange = (values) => {
    if (values === 'NS') {
      setEncrypNS(false);
    } else {
      setEncrypNS(true);
    }
  };

  function handleUpdateTags(tags) {
    setListOfTags(tags);
  }

  async function onFinish(values) {
    device.dev_eui = values.dev_eui;
    device.client_id = values.client_id;
    device.tags = listOfTags;
    device.contact_id = values.contract_id;
    device.activation = values.activation;
    device.nwkskey = values.nwkskey || null;
    device.dev_addr = values.dev_addr;
    device.app_eui = values.app_eui;
    device.app_key = values.app_key || null;
    device.counters_size = values.counters_size;
    device.dev_class = values.dev_class;
    device.encryption = values.encryption;
    device.appskey = values.appskey || null;

    confirm({
      title: 'Confirma o cadastro do dispositivo ?',
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk() {
        dispatch(DevicesActions.async_add_device([device]));
      },
    });
  }

  useEffect(() => {}, [listOfTags]);

  return (
    <Modal
      title="Cadastro de dispositivo"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      keyboard
      width={600}
      bodyStyle={{ height: '600px' }}
    >
      <Form
        form={form}
        {...formItemLayout}
        layout="vertical"
        onFinish={onFinish}
        size="large"
        style={{ textAlign: 'left', padding: 30, width: '100%' }}
      >

        <Form.Item
          label="Contrato"
          name="contract_id"
          rules={[
            {
              required: false,
              message: 'Por favor selecione um contrato!',
            },
          ]}
        >
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Selecione o Contrato"
            optionFilterProp="children"
            filterOption={
              (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
          >
            {contractsList.map((contract) => (
              <Option key={contract.key} value={contract.id}>
                {contract.id}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Device EUI"
          name="dev_eui"
          rules={[
            {
              required: true,
              message: 'Por favor adicione o Device EUI!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            placeholder="3ba4bs9ddb5fad3e"
            maxLength="16"
          />
        </Form.Item>

        <Form.Item
          label="Tags"
          name="tags"
          rules={[
            {
              required: false,
              message: 'Por favor selecione uma tag!',
              type: 'array',
            },
          ]}
        >
          <EditableTagGroup newtags={handleUpdateTags} />
        </Form.Item>

        <Form.Item
          label="Ativação"
          name="activation"
          rules={[
            {
              required: true,
              message: 'Por favor selecione a Ativação!',
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder="Ativação"
            onChange={activationChange}
          >
            <Option key="ABP" value="ABP">ABP</Option>
            <Option key="OTAA" value="OTAA">OTAA</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="NwsKey"
          name="nwkskey"
          rules={[
            {
              required: false,
              message: 'Por favor adicione o NwsKey!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            placeholder="Nwskey"
            disabled={activeABP}
            maxLength="32"
          />
        </Form.Item>

        <Form.Item
          label="Dev Address"
          name="dev_addr"
          rules={[
            {
              required: false,
              message: 'Por favor adicione o Device Address!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            placeholder="Dev Address"
            disabled={activeABP}
            maxLength="8"
          />
        </Form.Item>

        <Form.Item
          label="App EUI"
          name="app_eui"
          rules={[
            {
              required: true,
              message: 'Por favor adicione o App EUI!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            placeholder="App EUI"
            maxLength="16"
          />
        </Form.Item>

        <Form.Item
          label="Counter Size"
          name="counters_size"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o Counter Size!',
            },
          ]}
        >
          <Select style={{ width: '100%' }} placeholder="Counter">
            <Option key="counter_size_4" value={4}>4</Option>
            <Option key="counter_size_2" value={2}>2</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Dev Class"
          name="dev_class"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o Dev Class!',
            },
          ]}
        >
          <Select style={{ width: '100%' }} placeholder="Dev">
            <Option key="A" value="A">A</Option>
            <Option key="C" value="C">C</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Encriptação"
          name="encryption"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o Encryption!',
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            onChange={encryptionChange}
          >
            <Option key="APP" value="APP">APP</Option>
            <Option key="NS" value="NS">NS</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Apps KEY"
          name="appskey"
          rules={[
            {
              required: false,
              message: 'Por favor adicione o Apps Key!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            placeholder="Apps KEY"
            maxLength="32"
            disabled={encryptNS}
          />
        </Form.Item>

        <Form.Item
          label="App Key"
          name="app_key"
          rules={[
            {
              required: false,
              message: 'Por favor adicione o App Key!',
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            placeholder="App KEY"
            maxLength="32"
            disabled={encryptNS}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

DeviceRegister.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeviceRegister;
