import { ACTION_TYPES } from '../../actions/clientActions';

const INITIAL_STATE = {
  clientsList: [],
  loading: false,
  loadMoreClients: true,
  client: {
    id: 0,
    cpf_cnpj: '',
    company_name: '',
    email: '',
    is_active: false,
    c_type: '',
    phone: '',
    created_at: '',
    updated_at: '',
  },
};

const ClientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.ADD_CLIENT:
      return {
        ...state,
        client: action.payload,
        clientsList: [...state.client, action.payload],
      };

    case ACTION_TYPES.SHOW_CLIENTS:
      return {
        ...state,
        clientsList: [...state.clientsList, ...action.payload],
        loading: true,
      };

    case ACTION_TYPES.SHOW_ONE_CLIENT:// get de info de um cliente
      return {
        ...state,
        client: action.clientInfo,
        loading: true,
      };// action utilizada no dashboard do client ou provider

    case ACTION_TYPES.LOAD_MORE_CLIENTS:
      return { ...state, loadMoreClients: action.value };

    case ACTION_TYPES.CLEAR_CLIENT_LIST:
      return { ...state, clientsList: [] };

    default:
      return state;
  }
};

export default ClientsReducer;
