import Axios from 'axios';

const api = Axios.create({
  baseURL: 'https://lora.nlt-iot.com',
  config: {
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  },
});

// inclusão do token nas requisições
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default api;
